@charset "UTF-8";

/*
Project:	     BERNATEK ARCHITEKCI
Version:	     1.0
Assigned to:     siter.pl
*/

/* ===================================================================================
Boostrap v4.0-a-6
=================================================================================== */

/*!
 * Bootstrap v4.0.0-alpha.6 (https://getbootstrap.com)
 * Copyright 2011-2016 The Bootstrap Authors
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";


// Core CSS
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/grid";

// Utility classes
@import "../../node_modules/bootstrap/scss/utilities";


/* ===================================================================================
APP
=================================================================================== */

//Vendor
@import "vendor/swiper.scss";
@import "vendor/hamburgers/hamburgers.scss";
@import "vendor/jquery.mCustomScrollbar.scss";


//
// Var
//

$color-white: #fff;
$color-dark: #000;
$space-base: 3.125rem;
$space-db: $space-base * 2;
$space-lg: $space-base * 4;


/* ==========================================================================
General Style
========================================================================== */

body{
    background-color: $color-dark;
    border: solid $space-base $color-white;
    min-height: 100vh;
    padding: 0;
    margin: 0;
    position: relative;
}

#wrapper{
    position: relative;
    overflow: hidden;
}

img{
    max-width: 100%;
    height: auto;
}

.block-img{
    display: block;
    width: 100%;
}

.block-link{
    display: block;
}

//
// Preloader
//

#preloader {
    display: none;
    background-size: cover;
    background-position: 50%;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-dark;
    opacity: 1;
    visibility: visible;
    transition: all 1s;
}

#preloader.preloader-slide{
    left: $space-base;
    top: $space-base;
    right: $space-base;
    bottom: $space-base;
}

#preloader.hide{
    opacity: 0;
    visibility: hidden;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



/* ==========================================================================
GRID
========================================================================== */
.container, .container-fluid{
    padding-left: $space-base;
    padding-right: $space-base;
}

.row{
    margin-left: -$space-base;
    margin-right: -$space-base;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, 
.col-11, .col-12, .col, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, 
.col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, 
.col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, 
.col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, 
.col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, 
.col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, 
.col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl{
    padding-left: $space-base;
    padding-right: $space-base;
}

/* ==========================================================================
TYPOGRAPHY
========================================================================== */

body{
    font-family: 'Titillium Web', sans-serif;
}

//Typography
$font-family-base: 'Titillium Web', sans-serif;

//headings
$font-size-h1: 40px;
$font-size-h2: 30px;
$font-size-h3: 25px;
$font-size-h4: 20px;
$font-size-h5: 18px;
$font-size-h6: 16px;

//$headings-margin-bottom: ($spacer / 2);
$headings-font-family:   'Titillium Web', sans-serif;
$headings-font-weight:   600 !default;
$headings-line-height:   1 !default;
$headings-color:         $color-white;


h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  //margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 2px;
}

h1, .h1 { font-size: $font-size-h1; }
h2, .h2 { font-size: $font-size-h2;}
h3, .h3 { font-size: $font-size-h3; }
h4, .h4 { font-size: $font-size-h4; }
h5, .h5 { font-size: $font-size-h5; }
h6, .h6 { font-size: $font-size-h6; }


p{
    font-weight: 300;
    font-size: 16px;
    color: $color-dark;
    margin: 0;
    margin-bottom: 32px;
}

strong{
    font-weight: 600;
}
a, a:focus, a:hover, a:active, a.active{
    text-decoration: none;
    outline: none;
}

.btn{
    color: $color-white;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    padding: 16px 25px;
    border:2px solid $color-white;
    background-color: transparent;
    transition: all 0.35s;
}

.btn:hover{
    background-color: $color-white;
    color: $color-dark;
}

.fake-btn:hover{
    background-color: transparent;
    color: $color-white;
}



/* ==========================================================================
Header
========================================================================== */

.header{
    display: flex;
    align-items: center;
    padding-left: $space-db;
    padding-right: $space-db;
    padding-top: $space-base;
    padding-bottom: $space-base;
}

.hamburger-wrapper, .header-language{
    width: $space-db;
}

.header-title, .header-logo, .header-language{
    width: 33.3%;
}

.header-logo{
    text-align: center;

    a{
        margin-left: -$space-db;
    }
}

.hamburger{
    outline: none!important;
    border: 0px;
    padding: 0;
    margin: 0;
    width: 26px;
    height: 16px;
    position: relative;
    top: 3px;
    z-index: 9999;
}

.header-title{
    h2{
        margin: 0;
        font-weight: 700;
        text-transform: uppercase;
    }
}

.header-title-mobile{
    display: none;
}

ul.list-lang{
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: flex-end;
}

ul.list-lang > li{
    padding: 10px;
}

ul.list-lang > li:last-child{
    padding-right: 0;
}

ul.list-lang > li > a{
    text-transform: uppercase;
    font-weight: 600;
    color: $color-white;
    display: inline-block;
    position: relative;
    font-size: 18px;
}

ul.list-lang > li > a.active:after{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -7px;
    height: 2px;
    background-color: $color-white;
}

/* ==========================================================================
Site nav
========================================================================== */

body:after{
    content: "";
    position: absolute;
    left: -$space-base;
    top: -$space-base;
    bottom: - $space-base;
    width: $space-base;
    background-color: $color-white;
    z-index: 9999;
}

#site-nav{
    background-color: $color-dark;
    width: 440px;
    //position: fixed;
    //left: $space-base;
    //top: $space-base;
    //bottom: $space-base;
    position: absolute;
    left: 0;
    top: 0px;
    bottom: 0px;
    z-index: 999;
    padding-top: $space-db;
    padding-bottom: $space-db;
    display: flex;
    justify-content: flex-start;
    padding-top: 150px;      
    flex-direction: column;
    flex-wrap: wrap;
    transition: all 0.5s ease;
    transform: translateX(-100%);
}

#site-nav .site-nav-inner{
    transition: all 0.5s ease;
    opacity: 0;
    visibility: hidden;
    transform: translateX(-30px);
}

#site-nav ul.menu{
    list-style: none;
    padding: 0;
    margin: 0;
    padding-left: $space-db;
    width: 100%;
    
}

#site-nav ul.menu li{
    margin-bottom: 1rem;
}

#site-nav ul.menu li a{
    color: $color-white;
    font-size: 18px;
}

#site-nav ul.menu li a.disabled{
    opacity: 0.4;
}

#site-nav .contact-data{
    padding-left: $space-db;
    width: 100%;
    margin-top: 64px;
    }

#site-nav .contact-data p{
    color: $color-white;
    margin: 0;
    font-size: 14px;
}

a[href^="tel"] {
  color:$color-white;
}

//
// siteNav open
//

.siteNav-is-active #site-nav{
    transform: translateX(0%);
}


#site-nav .site-nav-inner.show-menu{
    opacity: 1;
    visibility: visible;
    transform: translateX(0px);
}


/* ==========================================================================
Footer
========================================================================== */

.footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: $space-db;
    padding-right: $space-db;
    padding-top: $space-base;
    padding-bottom: $space-base;
    color:#fff;

    p{
        color:inherit;
        margin-bottom: 0;
    }
}

.footer-copyright{
    font-weight: 300;
    font-size: 14px;
}

.footer-slogan{
    text-transform: uppercase;
}


/* ==========================================================================
Swiper
========================================================================== */

.swiper-container {
    //height: calc(100vh - 120px);
    background-color: $color-dark;
}

.swiper-slide {
  overflow: hidden;
  //padding-bottom: 44.5%;
  height: auto;
}

.swiper-slide:after{
    content: '';
    display: block;
    /* intrinsic aspect ratio */
    padding-bottom: 44.5%;
    height: 0;
}

.slide-inner{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-size: cover;
  background-position: center;
  z-index: 44;
}

.slide-content{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 20%;
    z-index: 20;
    padding: 20px 40px;
}

.slide-content .btn{
    width: 100%;
    display: block;
    min-width: 320px;
    text-align: center;
}

.swiper-pagination{
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 0;
    transform: translateY(100%);
}

.swiper-pagination-fraction{
    color: $color-white;
    font-size: 18px;
    font-weight: 600;
}

.swiper-button-prev, .swiper-button-next{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
    height: auto;
    background-image: none;
    line-height: 0;
    margin: 0;
    padding: 5px;
}

.swiper-button-next{
    right: 0;
}

.swiper-button-prev{
    left: 0;
}

.slider-more-projects{
    position: absolute;
    z-index: 20;
    left: 50%;
    transform: translateX(-50%);
    bottom: 48px;
}

//
// Swiper grid
//

#swiper-grid{
    position: relative;
    
    &:after, &:before{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 1px;
        background-color: #000;
        z-index: 44;
    }

    &:after{
        left: 0;
    }

    &:before{
        right: 0;
    }

    .swiper-wrapper{
        position: relative;
        //width: 100%!important;

        &:after{
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            height: 1px;
            background-color: #000;
            z-index: 44;
        }
    }
}

#swiper-grid{
    
    height: auto;
    .swiper-slide{
        height: auto;
        align-self: flex-start;
        padding: 0;
    }

    .swiper-slide a{
        display: block;
    }

    .slide-inner{
        //padding-bottom: 65.5%;
        position: relative;
        overflow: hidden;
        z-index: 1;
        overflow: hidden;
        border : 2px solid rgba(255, 255, 255, 1);
        //border-top: 2px;
        margin-left:-1px; 
        margin-right:-1px;
        border-bottom: 0px;
        margin-top: -1px;
        margin-bottom: -1px;
    }

    .slide-inner:after{
        content: '';
        display: block;
        /* intrinsic aspect ratio */
        padding-bottom: 65.5%;
        height: 0;
    }

    .slide-info{
        position: absolute;
        z-index: 2;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.0);
        transition: all 0.25s;
    }
    
    .slide-title{
        display: inline-block;
        left: 0;
        padding: 10px 25px;
        padding-top: 15px;
        background-color: $color-dark;
        color: $color-white;
        font-size: 16px;
        font-weight: 600;
        position: relative;
        margin-top: 15px;
        transition: all 0.3s;
        min-width: 80%;
    }

    .slide-title:before{
        content: "";
        position: absolute;
        left: 25px;
        top: 12px;
        height: 2px;
        width: 28px;
        background-color: $color-white;
        transition: all 0.3s;
    }
    
    .slide-description{
        color: $color-white;
        padding-left: 25px;
        padding-right: 10px;
        padding-top: 20px;
        padding-bottom: 35px;
        opacity: 0;
        transform: translateY(50px);
        transition: all 0.35s;
    }

    .slide-d-item{
        display: flex;
        align-items: top;
        font-size: 14px;
        margin-bottom: 4px;
    }

    .d-item-title{
        white-space: nowrap;
        margin-right: 15px;
        font-weight: 600;
        min-width: 112px;
    }

    .d-item-text{
        font-weight: 300;
    }
    
    .swiper-pagination{
        position: static;
        transform: translate(0%, 0%);
        padding: 25px 0;
    }
    .swiper-pagination-bullet{
        width: 26px;
        height: 6px;
        background-color: $color-white;
        border-radius: 0px;
        opacity: 0.4s;
    }

    .swiper-pagination-bullet:hover:not(.swiper-pagination-bullet-active){
        opacity: 0.6;
    }

    .slide-inner-more{
        position: absolute;
        right: 10px;
        bottom: 10px;
        width: 41px;
        height: 41px;
        background-image: url(../img/full.png);
        opacity: 0;
        transform: scale(0.4);
        transition: all 0.3s;
    }

    .slide-info.active{
        background-color: rgba(0, 0, 0, 0.7);
        
        .slide-description{
            opacity: 1;
            transform: translateY(0px);
        }

        .slide-title{
            background-color: $color-white;
            color: $color-dark;
        }   

        .slide-title:before{
            background-color: $color-dark;
        }

        .slide-inner-more{
            opacity: 0.7;
            transform: scale(1);
        }
        .slide-inner-more:hover{
            opacity: 1;
        }
    }
}




//
// Swiper thumbs
//

.swiper-gallery-wrapper{
    position: relative;
    overflow: hidden;
}


.gallery-thumbs{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    z-index: 20;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gallery-thumbs .swiper-thumb{
    width: 120px;
    height: 80px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    border:1px solid $color-white;
    margin: 0 2px;
    cursor: pointer;
}

.swiper-gallery-back{
    position: absolute;
    z-index: 44;
    right: 0;
    top: 0;
    padding: 8px 18px;
    font-size: 22px;
    font-weight: 300;
    font-family: Arial, sans-serif;
}

//
// Swiper Partners
//


.swiper-partners{
    .slide-inner{
        background-color: $color-white;
        border : 2px solid rgba(0,0,0,1)!important;
        border-bottom: 0px;
        margin-left:-1px; 
        margin-right:-1px;
        margin-top: -1px;
        margin-bottom: -1px;
    }

    .slide-partners{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .slide-partners-media{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 50px;
        right: 50px;
        top: 50px;
        bottom: 50px;
        background-repeat: no-repeat;
    }
}


/* ==========================================================================
Avb mode
========================================================================== */

#swiper.avb-mode-true{
    .swiper-slide{
        height: 100%;
        padding-bottom: 0;
    }

    .swiper-slide:after{
        padding-bottom: 0;
    }
}

#swiper-grid.avb-mode-true{
    .swiper-slide{
        height: 50%;
        padding-bottom: 0;
    }

    .swiper-slide:after{
        padding-bottom: 0;
    }
}

#swiper-grid .slide-inner{
    padding-bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

#swiper-grid .slide-inner:after{
    padding-bottom: 0;
}

#swiper-grid.avb-mode-true{
    padding-bottom: 40px;
}

#swiper-grid.avb-mode-true .swiper-pagination{
    position: absolute;
    padding: 0;
    bottom: 0;
}

#swiper-gallery.avb-mode-true{
    .swiper-slide{
        height: 100%;
        padding-bottom: 0;
    }

    .swiper-slide:after{
        padding-bottom: 0;
    }
}

/* ==========================================================================
Content
========================================================================== */

.page-content-wrapper{
    padding-left: $space-lg;
    padding-right: $space-lg;
}
.page-content{
    padding-top: $space-base;
    padding-bottom: $space-base;
    background-color: $color-white;
}

.page-content-fluid{
    padding: 0;
}

.media{
    position: relative;
}

.media-watermark{
    position: relative;

    &:after{
        content: "";
        position: absolute;
        right: 30px;
        bottom: 25px;
        width: 183px;
        height: 39px;
        background-image: url(../img/watermark.png);
    }
}

.watermark-left:after{
    right: auto;
    left: 30px;
}

//
// Content divider
//

.row-divider{
    position: relative;
}

.column-divider{
    padding-top: $space-base;
    padding-bottom: $space-base;
}

.column-divider:after, .column-divider:before{
    content: "";
    position: absolute;
    left: $space-base;
    height: 2px;
    width: 28px;
    background-color: $color-dark;
}

.column-divider:after{
    top: 0;
}

.column-divider:before{
    bottom: 0;
}

.column-divider-contact{
    padding-bottom: 0;
    align-self: stretch;
    display: flex;
}

.column-center{
    align-self: center;
}

.column-stretch{
    align-self: stretch;
    display: flex;
}

.contact-outer{
    width: 100%;
    align-self: flex-end;
}


.column-divider-contact:before{
    content: none;
}

.column-divider-contact:after{
    left: auto;
    right: $space-base;
}

.contact-data{
    p{
        margin: 0;
    }

    .paragraph-group{
        margin: 15px 0;
    }
}

.map-container{
    position: relative;
    padding-bottom: 55.5%;
    margin-top: 60px;
}

#map-canvas{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}


/* ==========================================================================
Media query
========================================================================== */

@media (max-width: 1599px){
    html{
        font-size: 8px;
    }

    .footer p{
        font-size: 14px;
    }

    .btn{
        font-size: 16px;
    }

    h2{
        font-size: 24px;
    }

    #site-nav{
        width: 340px;
        padding-top: 120px;
    }
    
    #site-nav ul.menu li{
        margin-bottom: 6px;
    }

    #site-nav ul.menu li a{
        font-size: 15px;
    }

    #site-nav .contact-data{
        margin-top: 20px;
    }

    ul.list-lang > li > a{
        font-size: 15px;
    }

    #swiper-grid .slide-title{
        font-size: 14px;
        margin-top: 10px;
        padding: 10px;
    }

    #swiper-grid .slide-title:before{
        left: 10px;
        top: 7px;
    }

    #swiper-grid .slide-description{
        padding-left: 10px;
        
    }
    #swiper-grid .slide-d-item{
        font-size: 12px;
        margin-bottom: 0;
    }
}

@media (max-width: 991px){
    body{
        border:0px;
    }

    .page-content-wrapper{
        padding-left: 3.125rem;
        padding-right: 3.125rem;
    }

    .header {
        padding-left: 3.125rem;
        padding-right: 3.125rem;
        padding-top: 1.5rem;
        padding-bottom: 2rem;
    }

    .header-title{
        display: none;
    }

    .header-title-mobile{
        display: block;
        padding-left: $space-base;
        padding-top: $space-base;
        padding-bottom: $space-base;
    }

    .header-logo a{
        margin-left: 0px;
    }
    .header-logo{
        width: auto;
        padding-right: 40px;
        padding-left: 20px;
        margin-right: auto;
    }

    ul.list-lang > li > a{
        font-size: 12px;
    }

    .footer{
        flex-direction: column;
        padding-left: 3.125rem;
        padding-right: 3.125rem;
        padding-top: 1.5rem;
        padding-bottom: 1rem;
    }

    .footer p{
        text-align: center;
    }

    .footer-copyright, .footer-slogan, .footer-siter{
        padding: 12px 0;
    }

    #swiper, #swiper-gallery{
        height: 75vh!important;
    }

    .slide-content{
        left: 0;
        right: 0;
        transform: translateX(0%);
    }

    .slide-content .btn {
        width: 100%;
        display: block;
        min-width: 100%;
        text-align: center;
    }

    .slider-more-projects{
        left: 0;
        right: 0;
        text-align: center;
        transform: translateX(0%);
    }

    .btn{
        padding: 12px 11px;
    }

    #swiper-grid .swiper-slide{
        height: auto;
        //padding-bottom: 42.5%;
    }
    #swiper-grid .swiper-slide:after{
        padding-bottom: 42.5%;
    }
    
    .gallery-thumbs .swiper-thumb{
        width: 80px;
        height: 55px;
        margin-bottom: 2px;
    }

    #site-nav{
        width: 320px;
        max-width: 80%;
        padding-top: 70px;
    }

    #site-nav ul.menu, #site-nav .contact-data{
        padding-left: 3.125rem;
    }

    .column-divider:before{
        content: none;
    }

    #swiper-grid .d-item-title{
        min-width: 100px;
    }
}


@media (max-width: 767px){
    #swiper-grid .swiper-slide{
        height: auto;
        //padding-bottom: 82.5%;
    }

    #swiper-grid .swiper-slide:after{
        content: '';
        display: block;
        /* intrinsic aspect ratio */
        padding-bottom: 82.5%;
        height: 0;
    }

    .map-container{
        height: 450px;
    }
}

